.work-exp-container {
    width: 100%;
    min-height: calc(100vh - 6rem);
    margin: 6rem 0 0 0;
}

.ventures {
    max-width: 1440px;
    margin: auto;
    
    a {
        font-weight: 600;
        font-size: inherit;
        color: var(--accent);
        text-transform: none;

        &:hover {
            text-decoration: underline;
        }

        &::selection {
            background-color: var(--background);
        }
    }

    .experience {
        margin: 0 0 3rem 0;
        display: grid;
        row-gap: 15px;

        .information {
            display: grid;
            row-gap: 10px;

            div {
                display: flex;
                justify-content: space-between;
            }

            span {
                font-size: 1rem;
                font-family: monospace;
                color: var(--text-primary);

                &:nth-child(even) {
                    justify-self: flex-end;
                }
            }

            .company {
                font-weight: 800;
            }

            .title {
                font-weight: 800;
            }

            .date {
                color: var(--moon);
                font-weight: 400;
            }

            .location {
                color: var(--moon);
                font-weight: 400;
            }
        }

        .description {
            width: 95%;
            margin: 0 auto;
            line-height: 20px;
            font-size: 0.8rem;
            display: flex;
            color: var(--switch-box-shadow);

            div {
                margin: 0;

                a {
                    display: inline;
                }

                p {
                    margin-bottom: 0.5rem;
                }
            }

            &::before {
                border: 0.5px solid var(--switch-box-shadow);
                background-color: var(--switch-box-shadow);
                vertical-align: top;
                margin-right: 10px;
                content: "";
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    .ventures {
        span {
            font-size: 0.7rem !important;
        }

        .description {
            font-size: 0.7rem !important;
        }
    }
}

@media only screen and (min-width: 414px) {
    .ventures {
        span {
            font-size: 0.85rem !important;
        }

        .description {
            font-size: 0.85rem !important;
        }
    }
}

@media only screen and (min-width: 766px) {
    .ventures {
        padding: 0 5%;

        span {
            font-size: 0.85rem !important;
        }

        .description {
            font-size: 0.85rem !important;
        }
    }
}

@media only screen and (min-width: 1023px) {
    .work-exp-container {
        margin: 9rem 0 0 0 !important;
    }

    .ventures {
        padding: 0 5%;
        
        span {
            font-size: 1rem !important;
        }

        .description {
            font-size: 0.85rem !important;
        }
    }
}

@media only screen and (min-width: 1439px) {
    .work-exp-container {
        margin: 9rem 0 0 0 !important;
    }

    .ventures {
        padding: 0 10%;
        
        span {
            font-size: 1.1rem !important;
        }

        .description {
            font-size: 0.9rem !important;
        }
    }
}