.about-me-container {
    width: 100%;
    height: calc(100vh);
    display: grid;
    align-content: center;
    max-width: 1440px;

    .about-me {
        display: grid;
        row-gap: 2rem;

        .big-font {
            align-self: center;
            font-size: 2.8rem;
        }

        .small-text {
            align-self: center;
            font-size: 1rem;
            line-height: 1.5rem;

            a {
                font-size: inherit;
                color: var(--accent);

                &:hover {
                    color: var(--text-primary);
                }

                &::selection {
                    background-color: var(--background);
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .big-font {
        font-size: 2.4rem !important;
    }

    .small-text {
        font-size: 0.85rem !important;
    }

    .about-me {
        row-gap: 1.5rem !important;
    }
}

@media only screen and (min-width: 767px) {
    .about-me-container {
        padding: 0 5%;
        margin: auto;
    }

    .about-me {
        row-gap: 1.5rem !important;
    }

    .big-font {
        font-size: 3.2rem !important;
    }

    .small-text {
        font-size: 1.1rem !important;
    }
}

@media only screen and (min-width: 1023px) {
    .about-me-container {
        padding: 0 5%;
        margin: auto;
    }

    .about-me {
        grid-template-columns: 3fr 3fr;
    }

    .big-font {
        font-size: 3rem !important;
    }

    .small-text {
        font-size: 0.9rem !important;
    }
}

@media only screen and (min-width: 1439px) {
    .about-me-container {
        padding: 0 10%;
    }

    .big-font {
        font-size: 3rem !important;
    }

    .small-text {
        font-size: 1.1rem !important;
    }
}
