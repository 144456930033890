.projects-container {
    min-height: 100vh;
    padding-top: 6rem;

    .projects {
        max-width: calc(1440px * .8);

        .project {
            margin: 0 0 2rem 0;
            display: grid;
            row-gap: 1rem;

            .metadata {
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-family: monospace;
                font-size: 1rem;

                .title {
                    font-weight: 800;
                }

                .date {
                    color: var(--moon);
                }
            }

            .description {
                width: 95%;
                margin: 0 auto;
                line-height: 20px;
                font-size: 0.85rem;
                display: flex;
                color: var(--switch-box-shadow);

                div {
                    margin: 0;

                    a {
                        display: inline;
                        text-transform: none;
                        color: var(--accent);
                    }

                    p {
                        margin-bottom: 0.5rem;
                    }
                }

                &::before {
                    border: 0.5px solid var(--switch-box-shadow);
                    background-color: var(--switch-box-shadow);
                    vertical-align: top;
                    margin-right: 0.8rem;
                    content: "";
                }
            }

            .tech-stack {
                display: flex;
                column-gap: 0.8rem;
                row-gap: 0.8rem;
                margin-left: 0.8rem;
                margin-bottom: 0.5rem;
                flex-wrap: wrap;

                span {
                    background-color: rgba(245, 139, 137, 0.2);
                    color: var(--accent);
                    font-weight: 800;
                    padding: 0.3rem 0.6rem;
                    border-radius: 8px;
                    border: 2px solid var(--accent);
                    font-size: 0.7rem;
                }
            }

            .external-links {
                margin-left: 0.8rem;
                display: flex;
                column-gap: 1.2rem;

                a svg {
                    height: 25px;
                    width: 25px;
                }
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    .metadata {
        font-size: 0.8rem !important;
    }

    .description {
        font-size: 0.7rem !important;
    }

    a svg {
        height: 20px !important;
        width: 20px !important;
    }
}

@media only screen and (min-width: 414px) {
    .metadata {
        font-size: 0.9rem !important;
    }

    .description {
        font-size: 0.8rem !important;
    }

    a svg {
        height: 25px !important;
        width: 25px !important;
    }
}

@media only screen and (min-width: 766px) {
    .projects {
        width: 90%;
        margin: 0 auto;
    }

    .metadata {
        font-size: 1rem !important;
    }

    .description {
        font-size: 0.85rem !important;
    }

    a svg {
        height: 25px !important;
        width: 25px !important;
    }
}

@media only screen and (min-width: 1023px) {
    .projects-container {
        padding-top: 9rem !important;
    }

    .projects {
        width: 90%;
        margin: 0 auto;
    }

    .metadata {
        font-size: 1rem !important;
    }

    .description {
        font-size: 0.85rem !important;
    }

    a svg {
        height: 25px !important;
        width: 25px !important;
    }
}

@media only screen and (min-width: 1439px) {
    .projects-container {
        padding-top: 9rem !important;
    }

    .projects {
        width: 80%;
        margin: 0 auto;
    }

    .metadata {
        font-size: 1.1rem !important;
    }

    .description {
        font-size: 0.9rem !important;
    }

    a svg {
        height: 30px !important;
        width: 30px !important;
    }

    .tech-stack span {
        font-size: .8rem !important;
    }
}
