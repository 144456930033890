:root {
    --background: #fffaf0;
    --text-primary: #0f110c;
    --accent: #f58b89;
    --sun: #ffa500;
    --moon: #777;
    --background-switch: #e0e0e0;
    --switch: #fff;
    --switch-box-shadow: #444;
    --btn-bg-primary: #f7e7ce;
    --btn-bg-secondary: #f29292;
    --btn-color-secondary: #fff;
}

[data-theme="dark"] {
    --background: #0f110c;
    --text-primary: #fff;
    --accent: #f58b89;
    --sun: #777;
    --moon: #fff;
    --background-switch: #232323;
    --switch: #4d4d4d;
    --switch-box-shadow: #e0e0e0;
    --btn-bg-primary: #f29292;
    --btn-bg-secondary: #f7e7ce;
    --btn-color-primary: #fff;
    --btn-color-secondary: #0f110c;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--text-primary);
    background-color: var(--background);
    transition: background 2s ease-in-out;

    &::selection {
        background-color: var(--accent);
    }
}

*,
::before,
::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

ul {
    list-style-type: none;
}

.App {
    padding: 1rem;
}

.page-header {
    margin: 0 0 2rem 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2rem;
    display: flex;
    text-align: center;
    color: var(--moon);
    max-width: calc(1440px * 0.8);

    &::before {
        content: "";
        height: 1px;
        width: 100%;
        margin-right: 20px;
        display: block;
        background-color: var(--moon);
        align-self: center;
    }

    &::after {
        content: "";
        height: 1px;
        width: 100%;
        margin-left: 20px;
        display: block;
        background-color: var(--moon);
        align-self: center;
    }
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (min-width: 766px) {
    .page-header {
        width: 90%;
        margin: 0 auto 2rem;
    }
}

@media only screen and (min-width: 1023px) {
    .page-header {
        width: 90%;
        margin: 0 auto 2rem;
        font-size: 1.4rem !important;
    }

    .App {
        padding: 1.25rem !important;
    }
}

@media only screen and (min-width: 1439px) {
    .page-header {
        width: 80%;
        margin: 0 auto 3rem;
        font-size: 1.6rem !important;
    }

    .App {
        padding: 1.25rem !important;
    }
}
