.archives-container {
    min-height: 100vh;
    padding-top: 6rem;

    .archives {
        max-width: calc(1440px * 0.8);

        .year-list {
            margin: 0 0 2rem 0;
            display: grid;
            row-gap: 1rem;

            h3 {
                font-size: 2.5rem;
                font-family: monospace;
                display: flex;
            }

            .archive {
                padding: 1rem 0;
                margin: 0 0 0.6rem 0;
                border-bottom: 1px solid var(--moon);
                display: grid;
                row-gap: 1rem;

                .metadata {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 0 1rem 0;

                    h6 {
                        font-size: 1rem;
                        text-decoration: underline;
                        width: 40%;
                    }

                    .tags {
                        display: flex;
                        column-gap: 0.4rem;

                        button {
                            background-color: rgba(245, 139, 137, 0.2);
                            color: var(--accent);
                            font-weight: 800;
                            padding: 0.3rem 0.6rem;
                            border-radius: 8px;
                            border: 2px solid var(--accent);
                            font-size: 0.7rem;
                        }
                    }
                }

                .description {
                    a {
                        color: var(--accent);
                        text-decoration: underline;
                    }
                }

                .iframe-container {
                    position: relative;
                    width: 100%;
                    padding-bottom: 56.25%;
                    height: 0;
                }

                .iframe-container iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }

                .location {
                    margin: 1rem 0 0 0;
                    display: flex;
                    justify-content: space-between;
                    font-size: 1rem;
                    font-weight: 800;

                    p {
                        color: var(--moon);
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 0px) {
    h3 {
        font-size: 2rem !important;
    }

    .metadata {
        h6 {
            font-size: 0.8rem !important;
        }

        .tags {
            button {
                font-size: 0.6rem !important;
            }
        }
    }

    .description {
        font-size: 0.7rem !important;
    }

    .location {
        font-size: 0.6rem !important;
    }
}

@media only screen and (min-width: 766px) {
    .archives {
        width: 90%;
        margin: auto;
    }

    h3 {
        font-size: 2.5rem !important;
    }

    .metadata {
        h6 {
            font-size: 1rem !important;
        }

        .tags {
            button {
                font-size: 0.7rem !important;
            }
        }
    }

    .description {
        font-size: 0.8rem !important;
    }

    .location {
        font-size: 0.7rem !important;
    }
}

@media only screen and (min-width: 1023px) {
    .archives-container {
        padding-top: 9rem !important;
    }

    .archives {
        width: 90%;
        margin: auto;

        .archive {
            margin: 0 0 1.2rem 0 !important;
        }
    }

    h3 {
        font-size: 3rem !important;
    }

    .metadata {
        h6 {
            font-size: 1.1rem !important;
        }

        .tags {
            button {
                font-size: 0.8rem !important;
            }
        }
    }

    .description {
        font-size: 0.8rem !important;
    }

    .location {
        font-size: 0.7rem !important;
    }
}

@media only screen and (min-width: 1439px) {
    .archives-container {
        padding-top: 9rem !important;
    }

    .archives {
        width: 60%;
        margin: auto;

        .archive {
            margin: 0 0 1.2rem 0 !important;
        }
    }

    h3 {
        font-size: 3rem !important;
    }

    .metadata {
        h6 {
            font-size: 1.5rem !important;
        }

        .tags {
            button {
                font-size: 0.9rem !important;
            }
        }
    }

    .description {
        font-size: 1rem !important;
    }

    .location {
        font-size: 0.9rem !important;
    }
}
